<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    title="关联序列号"
    :visible.sync="show"
    width="700px"
    class="add-account"
    v-loading="loading"
  >
    <div class="flex">
      <div>仓库：{{ currentItem.cwhName || '-' }}</div>
      <div>货位名称：{{ currentItem.cposName || '-' }}</div>
    </div>
    <div class="flex">
      <div>存货料号:{{ currentItem.cinvCode || '-' }}</div>
      <div>存货名称：{{ currentItem.cinvName || '-' }}</div>
    </div>
    <Page :showTitle="false" style="padding: 0; margin: 0">
      <div class="table-wrap">
        <el-table border :data="tableData" v-loading="loading" height="380px">
          <el-table-column
            label="序号"
            width="80"
            prop="index"
          ></el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            show-overflow-tooltip
            prop="code"
            label="序列号"
          />
        </el-table>
      </div>
      <Pagination
        :total="total"
        :page-size.sync="params.pageSize"
        :page.sync="params.pageNum"
        @change="getData"
      />
    </Page>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    return {
      loading: false,
      params: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      total: 0
    }
  },
  created() {
    this.getData()
  },

  methods: {
    async getData() {
      try {
        this.loading = true
        let params = {
          ...this.params,
          cInvCode: this.currentItem.cinvCode,
          cPosCode: this.currentItem.cposCode,
		  cWhCode: this.currentItem.cwhCode
        }
        let { list = [], total = 0 } =
          await this.$api.u8.queryListSnByInvPosition(params)
        this.tableData = list.map((code, index) => {
          return {
            code,
            index: this.params.pageSize * (this.params.pageNum - 1) + index + 1
          }
        })
        this.total = total
      } finally {
        this.loading = false
      }
    }
  },
  watch: {}
}
</script>

<style lang="less" scoped></style>
