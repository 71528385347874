<template>
  <Page>
    <template slot="search">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="用户" prop="name" v-show="showUserList">
          <SelectUserList
            ref="SelectUserList"
            :code.sync="params.userId"
            :name.sync="params.name"
            clearable
          />
        </el-form-item>
        <el-form-item label="存货料号" prop="cinvCode">
          <SelectGoodsCode
            :code.sync="params.cInvCode"
            :name.sync="params.cInvName"
          />
        </el-form-item>
        <el-form-item label="存货名称" prop="cinvName">
          <SelectGoodsName
            :code.sync="params.cInvCode"
            :name.sync="params.cInvName"
          />
        </el-form-item>
		<el-form-item label="是否有存货" prop="hasStorage">
		  <el-select v-model="params.hasStorage" placeholder="请选择" clearable>
		      <el-option label="是" value="1" />
			  <el-option label="否" value="0" />
		    </el-select>
		</el-form-item>
      </el-form>
    </template>
    <el-button type="primary" style="margin-bottom: 12px" @click="toExport1()">
      导出
    </el-button>
    <div class="table-wrap">
      <el-table border :data="tableData" v-loading="loading">
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="cposName"
          label="货位名称"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="cinvCode"
          label="存货料号"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="cinvName"
          label="存货名称"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="cinvCName"
          label="存货分类"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="cinvStd"
          label="规格型号"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop=""
          label="数量"
        >
          <template slot-scope="{ row }">
            {{ row.iquantity | dividedInto }}
          </template>
        </el-table-column>

        <!-- <el-table-column
          header-align="center"
          show-overflow-tooltip
          label="关联序列号"
          width="150"
        >
          <template slot-scope="{ row }">
            <el-button
              size="medium"
              type="text"
              @click="
                currentItem = row
                visible = true
              "
            >
              查询
            </el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <Pagination
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.pageNum"
      @change="getData"
    />
    <Modal :currentItem="currentItem" v-if="visible" :visible.sync="visible" />
  </Page>
</template>

<script>
import watchParamsGetData from '../../mixins/watchParamsGetData'
import Modal from './components/Modal.vue'
export default {
  components: { Modal },
  data() {
    return {
      visible: false,
      params: {},
      tableData: [],
	  showUserList: this.$store.state.userInfo.post == 2
    }
  },
  mixins: [watchParamsGetData],
  methods: {
    selfReset() {
      this.params.userId = ''
      this.params.name = ''
      this.params.cInvCode = ''
      this.params.cInvName = ''
    },
    async getData() {
      try {
        this.loading = true
        let params = { ...this.params }
        const { list, total } = await this.$api.u8.queryPageInvPosition(params)
        this.tableData = list

        this.total = total
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async toExport1() {
      await this.$api.u8.exportInvPosition(this.params)
      this.$message.success('导出成功')
    }
  }
}
</script>
